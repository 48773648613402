.label {
    font-size: inherit;
    font-weight: normal;
  }
  
  .debounceInput {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 12px 20px;
    color: inherit;
    background-color: #3b3f45;
    border: 1px solid #2d2f34;
  }
  
  .debounceInputLight {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 12px 20px;
    color: inherit;
    background-color: #fff;
    border: 1px solid #bababf;
  }
  