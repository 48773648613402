.mapboxgl-popup {
  padding: 0;
  white-space: no-wrap;
  background-color: none;
  min-width: 300px;
  border: none;
}

.mapboxgl-popup-content {
  background: none;
  padding: 0;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  align-self: flex-start;
  border-bottom: none;
  border-left: none;
}

.mapboxgl-popup-tip {
  border: 10px solid transparent;
  height: 0;
  width: 0;
  z-index: 1;
}

.popup-arrow-light {
  border-top-color: #fff !important;
}

.popup-arrow-dark {
  border-top-color: #33373e !important;
}
