.pac-container {
    z-index: 2000 !important; 
    background-color: var(--palete-background-default);
}

.pac-item,
.pac-item-query {
  padding: 4px 10px;
  color: var(--palete-text-primary);
}
.pac-item:hover {
  background-color: var(--palete-primary-main);
  color: var(--palete-text-secondary);
}